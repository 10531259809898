<template>
  <section class="static">
    <div class="container">
      <h1>Syarat dan Ketentuan</h1>
      <h4 class="mt40">Pendahuluan</h4>
      <p class="mb30 text-justify">
        Dengan mengklik tombol "saya setuju" atau menunjukkan penerimaan syarat
        dan ketentuan ini, anda telah menyatakan persetujuan dan mengakui bahwa
        anda telah membaca, menerima, dan setuju untuk dilakukannya ketentuan
        ini. Jika anda tidak setuju dengan syarat dan ketentuan ini, anda
        dilarang untuk melakukan proses lebih lanjut dalam pelayanan yang akan
        Kami berikan. <br />
        Pemberitahuan perselisihan: anda setuju bahwa perselisihan antara anda
        dengan penyedia layanan media terkait dengan ketentuan atau layanan akan
        diatasi oleh mediasi dan jalur hukum. <br />
        Perusahaan Kami mengoperasikan situs web yang bernama
        https://www.surfaceskinhabit.com dan akan mengoperasikan aplikasi
        seluler Surface: Personalized Skincare kedepannya untuk memudahkan
        pelayan yang Kami berikan "platform". Akses dan penggunaan anda terhadap
        platform atau apa pun yang terkait dengannya, termasuk kontennya
        ("konten"), setiap produk atau layanan yang disediakan melalui platform
        atau oleh Web Site Kami, dan situs web, perangkat lunak, atau aplikasi
        yang berafiliasi apa pun yang dimiliki atau dioperasikan oleh Web Site
        Kami akan diatur oleh syarat dan ketentuan ini ("syarat dan ketentuan"
        atau "persetujuan"). <br />
        Kami harapkan anda dapat membaca persyaratan dan ketentuan ini dengan
        cermat karena ini menetapkan persyaratan penting bagi anda untuk
        mengetahui tentang layanan Kami. Dalam ketentuan ini, istilah "anda" dan
        "milik anda" adalah orang yang menggunakan layanan Kami. <br />
        Jika anda memiliki masalah darurat terkait kondisi medis, segera hubungi
        dokter anda sebelum menggunakan pelayanan ataupun penggunaan Produk
        Kami. <br />
        Layanan yang kami berikan bukan menjadi acuan terhadap kondisi atau
        riwayat medis yang anda miliki, khususnya bagi anda yang memiliki
        kondisi atau riwayat medis tertentu. <br />
      </p>
      <h4 class="mt40">Penerimaan Syarat Dan Ketentuan</h4>
      <p class="mb30 text-justify">
        Akses anda kedalam penggunaan layanan Kami tunduk pada ketentuan yang
        ada, dan juga semua hukum dan peraturan yang berlaku di Indonesia. Jika
        anda tidak menerima dan setuju untuk terikat dengan peraturan ini secara
        keseluruhan, maka anda dilarang mengunjungi, mengakses, mendaftar dengan
        dan / atau menggunakan layanan atau informasi apa pun atau konten yang
        disediakan melalui layanan ini, kecuali jika diperlukan untuk meninjau
        ketentuan yang ada. Layanan ini terus dalam pengembangan, dan Kami
        berhak merevisi atau menghapus bagian apa pun yang ada di dalamnya.
        <br />
        Ketentuan dan layanan merupakan kebijakan yang kapan saja dan tanpa
        pemberitahuan sebelumnya dapat berubah sesuai dengan peraturan dan
        Undang – Undang yang berlaku. Setiap perubahan pada ketentuan ini
        berlaku setelah dilakukannya pemostingan ke platform. Kecuali dinyatakan
        sebaliknya, konten baru yang ditambahkan ke layanan juga tunduk pada
        ketentuan ini setelah memposting ke platform. Jika anda tidak setuju
        dengan ketentuan atau syarat atau apa pun di sini, satu-satunya solusi
        anda adalah menghentikan penggunaan layanan yang anda miliki. Penggunaan
        anda yang berkelanjutan setelah perubahan pada ketentuan yang telah
        diposting merupakan penerimaan anda secara langsung terhadap ketentuan
        ini sebagaimana perubahan yang telah dimodifikasi dan ditampilkan.
        <br />
      </p>
      <h4 class="mt40">Hubungan Anda Dengan Kami</h4>
      <p class="mb30 text-justify">
        Kami adalah Web Site yang menyediakan layanan berupa produk dan jasa
        bagi individu yang mendaftar sebagai pengguna layanan Kami ("pengguna").
        Anda dapat melakukan konsultasi dan pembelian produk sebagaimana yang
        Kami tawarkan di dalam pelayan yang Kami miliki di Web Site Kami. <br />
        Dengan menerima ketentuan ini, anda mengakui dan setuju bahwa, Kami
        dapat mengirim pesan, laporan, dan email kepada anda melalui layanan
        mengenai hasil diagnosis dan / atau produk yang anda inginkan. Anda
        memahami dan menyetujui bahwa itu adalah tanggung jawab anda sepenuhnya
        untuk memantau dan menanggapi pesan, laporan, dan email ini. Anda
        bertanggung jawab atas segala kehilangan, cedera, atau klaim dalam
        bentuk apa pun dari ke-tidak perhatian anda untuk membaca atau
        menanggapi pesan-pesan yang diberikan atau untuk kegagalan anda dalam
        mematuhi setiap rekomendasi perawatan yang diberikan. <br />
        Meskipun anda tidak membangun hubungan pasien-dokter atau
        pasien-penyedia layanan kesehatan, dengan menggunakan layanan Kami, anda
        wajib memberikan keterangan kepada Kami atas nama anda, informasi
        pribadi, termasuk informasi kesehatan, yang dapat digunakan oleh Kami
        dalam menyikapi permasalahan yang anda konsultasikan kepada Kami sesuai
        dengan kebijakan privasi Kami. <br />
        Silakan lihat bagian "kebijakan privasi" dan bagian "informasi yang
        dilindungi" di bawah ini untuk informasi tambahan. <br />
      </p>
      <h4 class="mt40">Produk Berlangganan</h4>
      <p class="mb30 text-justify">
        Produk tertentu yang tersedia untuk dibeli melalui layanan mengharuskan
        anda membeli produk secara berlangganan. Untuk produk berbasis
        langganan, anda secara otomatis akan ditagih secara berkala seperti yang
        dijelaskan pada keterangan produk. Anda dapat membatalkan berlangganan
        kapan saja hingga empat puluh delapan (48) jam sebelum tanggal
        pemrosesan bulanan anda yang berlaku berlangganan dengan mengirim email
        ke hello@surfaceskinhabit.com. <br />
        Untuk menyederhanakan pengalaman pengguna di platform, anda hanya akan
        melihat dan menjadi diharuskan membayar harga berlangganan "total".
        Namun, jika berlangganan produk dan masih memerlukan konsultasi dengan
        tenaga medis, maka total harga yang anda bayarkan termasuk dengan
        konsultasi terhadap tenaga medis Kami <br />
      </p>
      <h4 class="mt40">Penggunaan Dan Ketersediaan Terbatas</h4>
      <p class="mb12 text-justify">
        Layanan Kami saat ini hanya tersedia untuk individu yang berlokasi di
        jakarta dan setidaknya berusia delapan belas (18) tahun atau lebih, atau
        pada setidaknya usia yang ditetapkan sebagai dewasa di dalam Undang –
        Undang, jika belum mencapai delapan belas (18) tahun dan ingin
        mengunjungi, mengakses, mendaftar atau menggunakan layanan Kami, maka
        anda wajib :
      </p>
      <ol>
        <li>
          Mendapatkan Penjamin yang dapat menjamin kepada Kami bahwa anda
          memenuhi semua persyaratan kelayakan ini,
        </li>
        <li>
          Penjamin yang ikut menyetujui untuk mematuhi semua hukum yang berlaku
          untuk mengunjungi, mengakses, mendaftar dan menggunakan layanan yang
          Kami berikan, dan
        </li>
        <li>
          Penjamin menyetujui bahwa anda hanya akan menggunakan layanan untuk
          tujuan yang sah menurut hukum. Layanan Kami tunduk pada peraturan
          negara dan dapat berubah dari waktu ke waktu berdasarkan perubahan
          Peraturan yang ada.
        </li>
      </ol>
      <p class="mt-4 mb30 text-justify">
        Produk-produk tertentu yang tersedia melalui layanan tunduk pada usia
        tambahan pembatasan, dan tidak semua produk atau layanan pada platform
        Kami tersedia untuk semua umur. <br />
        Dalam beberapa kasus, layanan Kami mungkin memerlukan informasi terkait
        kondisi yang anda miliki berdasarkan diagnosis dan / atau perawatan yang
        anda miliki dari dokter yang pernah melakukan perwatan terhadap
        kesehatan anda. <br />
      </p>
      <h4 class="mt40">Persetujuan Untuk Penggunaan Layanan Telehealth</h4>
      <p class="mb30 text-justify">
        Telehealth melibatkan pengiriman layanan kesehatan menggunakan
        komunikasi elektronik, teknologi informasi atau cara lain antara layanan
        tenaga medis dan pengguna layanan. Sedangkan ketentuannya layanan
        perawatan kesehatan menggunakan telehealth dapat menawarkan manfaat
        potensial tertentu, ada juga risiko potensial yang terkait dengan
        penggunaan telehealth. Layanan telehealth bukan untuk pengganti
        perawatan kesehatan pribadi dalam semua kasus. Untuk menggunakan
        layanan, anda akan diminta untuk meninjau dan menyetujui persetujuan
        berdasarkan informasi mengenai penggunaan telehealth ("persetujuan
        pengguna layanan") yang akan diberikan kepada anda oleh tenaga medis dan
        penyedia melalui platform. <br />
      </p>
      <h4 class="mt40">
        Tugas Untuk Memberikan Informasi, Akses, Dan Konektivitas
      </h4>
      <p class="mb30 text-justify">
        Anda bertanggung jawab untuk menyediakan dan memelihara, dengan risiko
        Anda sendiri terkait opsi dan biaya, kemampuan perangkat lunak dan
        perangkat keras yang sesuai untuk memungkinkan penggunaan Layanan,
        termasuk tetapi tidak terbatas pada, komputer atau perangkat seluler
        dengan video akses kamera dan internet. Anda sepenuhnya bertanggung
        jawab atas segala biaya, termasuk Koneksi internet atau biaya seluler,
        yang Anda keluarkan saat mengakses Layanan. Anda juga memiliki kewajiban
        untuk memberikan informasi yang benar, akurat, dan lengkap dalam bentuk
        apa pun atau komunikasi lain yang Anda serahkan melalui Layanan Kami.
        Kami berhak untuk mengubah konfigurasi akses, termasuk perangkat lunak,
        perangkat keras atau lainnya persyaratan Layanan setiap saat tanpa
        pemberitahuan sebelumnya. <br />
      </p>
      <h4 class="mt40">Rahasia pribadi</h4>
      <p class="mb30 text-justify">
        Kami memahami pentingnya kerahasiaan dan privasi mengenai informasi
        pribadi Anda. Silakan lihat Kebijakan Privasi Kami untuk penjelasan
        bagaimana Kami dapat mengumpulkan, menggunakan, dan mengungkapkan
        informasi pribadi Anda. <br />
      </p>
      <h4 class="mt40">Informasi Kesehatan yang Dilindungi</h4>
      <p class="mb30 text-justify">
        Ketika anda mengatur akun dengan Kami, anda dapat membuat hubungan
        langsung dengan Kami yang memungkinkan anda untuk mengakses dan / atau
        memanfaatkan beragam fungsi layanan sebagai pengguna. Sebagai bagian
        dari hubungan itu, anda memberikan informasi kepada Kami, termasuk
        tetapi tidak terbatas pada nama anda, alamat email, alamat pengiriman
        dan nomor telepon, yang dapat Kami kumpulkan, gunakan, dan ungkapkan di
        sesuai dengan kebijakan privasi Kami, dan Kami tidak menganggapnya
        sebagai "kesehatan" atau "informasi medis. <br />
        Namun, informasi dalam medis dilindungi oleh undang-undang baik milik
        anda maupun tenaga medis Kami yang memberikan pelayanan. <br />
      </p>
      <h4 class="mt40">Penggunaan dan Kepemilikan Layanan</h4>
      <p class="mb30 text-justify">
        Layanan dan informasi serta konten yang tersedia melalui layanan adalah
        dilindungi oleh undang-undang hak cipta di seluruh dunia. Tunduk pada
        ketentuan ini, Kami memberi anda lisensi terbatas untuk dapat diakses
        dan tidak dapat ditransfer untuk dijadikan sebagai layanan pihak lain,
        kecuali ditentukan lain oleh Kami. Hak anda untuk menggunakan layanan
        apa pun atau konten, tunduk pada ketentuan ini dan semua hak dalam
        layanan dan konten dilindungi oleh Kami. Anda setuju bahwa Kami memiliki
        semua hak, kepemilikan, dan kepentingan dalam layanan (termasuk tetapi
        tidak terbatas pada, kode komputer, tema, objek, konsep, foto, deskripsi
        produk, posting blog, karya seni, animasi, suara, musikal komposisi,
        efek audiovisual, metode operasi, hak moral, dan dokumentasi). Anda
        tidak akan menghapus, mengubah atau mengaburkan hak cipta, merek dagang,
        tanda layanan atau pemberitahuan hak kepemilikan lainnya yang tergabung
        dalam atau menyertai layanan. Nama dan gambar, logo, tanda layanan
        terkait lainnya dan nama dagang yang digunakan pada atau sehubungan
        dengan layanan adalah merek dagang dari Perusahaan Kami dan tidak boleh
        digunakan tanpa izin, termasuk sehubungan dengan apa pun produk atau
        layanan pihak ketiga. Merek dagang, merek layanan, dan nama dagang
        lainnya yang mungkin muncul pada atau dalam layanan adalah milik dari
        pemiliknya masing-masing. <br />
      </p>
      <h4 class="mt40">Lisensi Untuk Informasi Diserahkan Melalui Layanan</h4>
      <p class="mb30 text-justify">
        Semua informasi yang Anda kirimkan melalui Layanan, baik dengan entri
        langsung, pengiriman, email atau sebaliknya, termasuk data, pertanyaan,
        komentar, komunikasi forum, atau saran (secara bersama-sama disebut,
        “Pengajuan”), akan, sejauh diizinkan oleh hukum yang berlaku, menjadi
        diperlakukan sebagai non-rahasia dan non-hak milik. Tunduk pada
        pengaturan yang Anda pilih, Anda memberi Kami pembayaran penuh, bebas
        royalti, hak yang tidak dapat dibatalkan, di seluruh dunia,
        non-eksklusif, dan sepenuhnya dapat disublisensikan (termasuk segala hak
        moral) dan lisensi untuk menggunakan, melisensikan, Mendistribusikan,
        mereproduksi, memodifikasi, mengadaptasi, melakukan secara publik, dan
        menampilkan secara terbuka, pengajuan (seluruhnya atau sebagian) untuk
        tujuan pengoperasian dan penyediaan layanan untuk anda. Anda menjamin
        bahwa pemegang hak kekayaan intelektual di seluruh dunia, termasuk hak
        moral, dalam pengajuan telah sepenuhnya dan efektif untuk melepaskan
        semua hak tersebut dan tidak dapat dibatalkan untuk memberikan hak
        lisensi yang disebutkan di atas. Anda setuju bahwa anda, bukan Kami,
        yang bertanggung jawab atas semua pengajuan yang anda berikan ke dalam
        pelayanan Kami. <br />
      </p>
      <h4 class="mt40">Penggunaan Yang Dilarang</h4>
      <p class="mb12 text-justify">
        Setiap pengguna dalam penggunaan layanan ini, dilarang :
      </p>
      <ol>
        <li>
          Melakukan segala penggunaan untuk pelanggaran hukum, dan penipuan atau
          tujuan jahat,
        </li>
        <li>
          Penggunaan yang dapat merusak, menonaktifkan, membebani, atau merusak
          siapa pun, atau jaringan yang terhubung mana pun,
        </li>
        <li>
          Penggunaan yang dapat mengganggu penggunaan dan kenikmatan pihak lain
          atas layanan,
        </li>
        <li>
          Penggunaan untuk mendapatkan akses tidak sah ke akun lain, sistem
          komputer, atau jaringan yang terhubung ke server atau sistem melalui
          peretasan, penambangan kata sandi atau cara lain,
        </li>
        <li>
          Penggunaan untuk mengakses sistem, data, atau informasi yang tidak
          dimaksudkan oleh Kami agar dapat diakses oleh pengguna,
        </li>
        <li>
          Penggunaan untuk memperoleh materi apa pun, atau informasi melalui
          cara apa pun yang tidak disediakan oleh Kami,
        </li>
        <li>
          Penggunaan untuk merekayasa balik, membongkar atau mendekompilasi
          setiap bagian atau teknologi pada layanan, atau
        </li>
        <li>Penggunaan apa pun selain yang dimaksudkan dalam layanan ini.</li>
      </ol>
      <p class="mt-4 mb30 text-justify">
        Selain itu, sehubungan dengan penggunaan layanan oleh anda, anda setuju
        bahwa anda tidak akan:
      </p>
      <ol>
        <li>
          Mengunggah atau mengirimkan pesan, informasi, data, teks, perangkat
          lunak atau gambar, atau lainnya konten yang melanggar hukum,
          berbahaya, mengancam, kasar, melecehkan, menyiksa, memfitnah, vulgar,
          atau tidak pantas sehubungan dengan ras, jenis kelamin, seksualitas,
          etnis, atau karakteristik intrinsik lainnya, atau yang dapat menyerang
          hak orang lain privasi atau publisitas;
        </li>
        <li>
          Membuat identitas palsu atau akun duplikat untuk tujuan menyesatkan
          orang lain atau menyamar sebagai orang atau entitas, termasuk, tanpa
          pembatasan, perwakilan Kami, atau secara tidak benar menyatakan atau
          salah menggambarkan afiliasi anda dengan seseorang atau entitas;
        </li>
        <li>
          Mengunggah atau mengirimkan materi apa pun yang anda lakukan tidak
          memiliki hak untuk mereproduksi, menampilkan, atau mentransmisikan
          berdasarkan hukum apa pun atau berdasarkan kontrak atau hubungan
          fidusia (seperti ketentuan kerahasiaan);
        </li>
        <li>
          Mengunggah file itu mengandung virus, trojan horse, worm, bom waktu,
          cancel-bots, file yang rusak, spyware atau perangkat lunak atau
          program serupa lainnya yang dapat merusak pengoperasian komputer atau
          properti orang lain;
        </li>
        <li>
          Menghapus semua atribusi penulis, legal pemberitahuan atau penunjukan
          eksklusif atau label yang anda unggah ke komunikasi apa pun fitur;
        </li>
        <li>
          Menggunakan fitur komunikasi layanan dengan cara yang merugikan
          memengaruhi ketersediaan sumber dayanya bagi pengguna lain (misalnya,
          teriakan berlebihan, penggunaan semua huruf besar, atau membanjiri
          posting berulang dari teks berulang);
        </li>
        <li>
          Mengunggah atau mengirimkan apa pun Iklan yang tidak diminta, materi
          promosi, "surat sampah," "spam," "surat berantai," "skema piramida,"
          "phishing" atau segala bentuk permohonan, komersial;
        </li>
        <li>
          Melanggar hukum setempat, negara bagian, nasional atau internasional
          yang berlaku; (saya) mengunggah atau mengirimkan materi apa pun yang
          melanggar paten, merek dagang, merek layanan, rahasia dagang, hak
          cipta, atau hak kepemilikan lainnya dari pihak mana pun;
        </li>
        <li>
          Menghapus atau merevisi apa pun materi yang diposting oleh orang atau
          entitas lain;
        </li>
        <li>
          Memanipulasi atau menampilkan layanan dengan menggunakan framing,
          mirroring atau teknologi navigasi serupa;
        </li>
        <li>
          Menyelidiki, memindai, menguji kerentanan atau melanggar tindakan
          otentikasi, layanan atau jaringan atau sistem terkait;
        </li>
        <li>
          Mendaftar, berlangganan, mencoba mendaftar, mencoba berlangganan,
          berhenti berlangganan, atau mencoba berhenti berlangganan, pihak mana
          pun untuk siapa pun layanan atau kontes, promosi atau undian apa pun
          jika anda tidak secara tegas diizinkan oleh pihak tersebut untuk
          melakukannya;
        </li>
        <li>
          Memanen atau mengumpulkan informasi tentang lainnya, termasuk alamat
          email;
        </li>
        <li>
          Menggunakan robot, laba-laba, pengikis, atau lainnya otomatis atau
          manual berarti mengakses layanan, atau menyalin, mengunduh,
          mendistribusikan atau mereproduksi konten atau informasi apa pun pada
          layanan; atau
        </li>
        <li>
          Membantu atau mengizinkan orang yang terlibat dalam kegiatan ini.
        </li>
      </ol>
      <p class="mt-4 mb30 text-justify">
        Kami berhak untuk mengambil tindakan hukum apa pun yang dianggap pantas
        sebagai tanggapan atas pelanggaran aktual atau dugaan pelanggaran
        tersebut di atas, termasuk, tanpa batasan, <br />
        Kami dapat bekerja sama dalam penghentian hal diatas dengan otoritas
        akses hukum dan / atau dan akun / atau pengguna termasuk Pihak ketiga
        dalam investigasi atas dugaan atau dugaan kejahatan.
      </p>
      <h4 class="mt40">Hak untuk Memantau</h4>
      <p class="mb30 text-justify">
        Kami berhak memantau penggunaan layanan yang Kami berikan setiap saat,
        dan menghapus materi apa pun yang mungkin ilegal, dan dapat menyebabkan
        Kami bertanggung jawab terhadap permasalahan hukum, norma dan etika baik
        secara pribadi maupun kelompok. <br />
      </p>
      <h4 class="mt40">Ketentuan Penjualan</h4>
      <p class="mb30 text-justify">
        Semua produk yang ditawarkan untuk dijual oleh Kami tergantung pada
        ketersediaan dan Kami memiliki batasan kuantitas pada setiap pesanan
        atau menolak semua atau sebagian dari suatu pesanan tanpa pemberitahuan
        sebelumnya. Jika terjadi kesalahan, Kami berhak untuk memperbaiki
        kesalahan tersebut dan merevisi pesanan Anda sesuai (yang mencakup
        pengisian harga yang benar) atau pembatalan pesanan dan pengembalian
        jumlah yang dibebankan. Harga produk dapat berubah kapan saja, tetapi
        perubahan tidak akan memengaruhi pesanan apa pun untuk produk yang Anda
        pesan. Anda bertanggung jawab atas biaya – biaya yang timbul, bea cukai
        atau pajak, retribusi, atau biaya pemerintah lainnya ("Pajak")
        sehubungan dengan pembelian produk Anda. Kami akan memungut Pajak yang
        berlaku jika Kami menentukan bahwa Kami memiliki kewajiban untuk
        memungut Pajak. Kami akan menyajikan estimasi Pajak Kami kumpulkan di
        checkout, kecuali di mana Kami telah dengan jelas menyatakan secara
        tertulis bahwa harga termasuk Pajak. Pajak aktual yang dibebankan dapat
        disesuaikan dari jumlah yang ditunjukkan saat checkout. <br />
        Kami hanya menerima metode pembayaran yang valid yang dapat digunakan
        untuk menyelesaikan pembelian melalui Layanan platform. Jika salah satu
        produk dalam pesanan Anda tidak tersedia, Kami hanya akan membebankan
        biaya, Pajak, dan biaya lain yang berlaku terkait produk yang termasuk
        dalam pengiriman. Sehubungan dengan pembelian apa pun Anda melalui
        layanan ini, Anda mungkin diminta memberikan informasi tertentu yang
        relevan untuk transaksi, termasuk, tanpa batasan, nomor kartu kredit
        Anda dan tanggal kedaluwarsa, alamat penagihan Anda, alamat pengiriman
        Anda, nomor telepon Anda dan / atau alamat email Anda. <br />
        Semua kartu kredit, kartu debit, dan transaksi moneter lainnya pada atau
        melalui Layanan terjadi melalui aplikasi pemprosesan pembayaran online
        yang dapat diakses melalui Layanan Internet. <br />
        Anda setuju untuk membayar biaya pengiriman dan penanganan, jika ada,
        yang ditunjukkan pada saat Anda melakukan pembelian. Kami berhak untuk
        menambah, mengurangi, menambah atau menghilangkan pengiriman dan
        penanganan biaya dari waktu ke waktu, tetapi Kami akan memberikan
        pemberitahuan perubahan yang berlaku untuk Anda sebelum Anda melakukan
        pembelian. Tanggal pengiriman atau waktu yang ditampilkan sebagai bagian
        dari proses checkout hanya merupakan perkiraan dan bukan terjamin.
        Kecuali Kami nyatakan sebaliknya secara tertulis melalui Layanan, risiko
        kehilangan atau kerusakan pada suatu produk beralih kepada Anda setelah
        pengiriman produk ke tempat yang ditunjuk oleh Kami pembawa. <br />
        Kami berhak untuk memperbaiki masalah dan masalah Pengguna berdasarkan
        kasus per kasus. Kami berhak, atas kebijakan Kami sendiri, untuk
        menyelesaikan masalah dan masalah pelanggan berdasarkan fakta dan
        keadaan masing-masing Pengguna. <br />
      </p>
      <h4 class="mt40">Penghentian</h4>
      <p class="mb30 text-justify">
        Kami dapat menghentikan anda atas penggunaan layanan atau fitur atau
        layanan Kami kapan saja dan untuk alasan apa pun tanpa pemberitahuan,
        termasuk, misalnya, untuk perilaku melanggar ketentuan ini. Ketentuan
        yang dimaksud di dalam hal ini adalah tentang keamanan layanan,
        aktivitas terlarang, hak cipta, merek dagang, pengiriman produk,
        penolakan, batasan tanggung jawab, penyelesaian sengketa, ganti rugi dan
        masalah lainnya antara anda dengan Kami. <br />
      </p>
      <h4 class="mt40">Penolakan</h4>
      <p class="mb30 text-justify">
        Konten dan informasi lain yang terkandung dalam Layanan disediakan oleh
        Kami sebagai sebuah kenyamanan. Pengguna yang mengandalkan Konten atau
        informasi dari Layanan lain akan menanggung risiko mereka sendiri.
        <br />
        Layanan ini disediakan pada dasar "sebagaimana adanya" atau "sebagaimana
        tersedia". Apa saja akses ke atau penggunaan layanan ini voluntary dan
        di sale risiko pengguna. Pihak Kami dan setiap pihak ketiga menawarkan
        produk atau layanan melalui layanan, termasuk kelompok medis, penyedia
        dan farmasi, untuk sejauh diizinkan oleh undang-undang, menolak semua
        garansi dan kondisi apa pun, baik tersurat maupun tersirat, pernyataan
        atau jika termasuk, termasuk, tanpa batasan, jaminan tersirat penjualan,
        non-pelanggaran hak-hak pihak ketiga, kualitas dan kesesuaian kepuasan
        untuk tujuan tertentu dengan regard ke layanan, dan dengan menghormati
        informasi apa pun, isi, produk, layanan, merchandise, atau materi
        lainnya disediakan pada atau melalui layanan atau platform. Kami tidak
        menjamin atau menjamin keakuratan, kelengkapan, keandalan, ketepatan
        waktu atau kegunaan layanan. Kami melakukannya tidak menjamin bahwa
        layanan akan fungsi tanpa tunda, gangguan, gangguan, imperfeksi,
        korupsi, serangan dunia maya, virus, malware, atau insiden yang sesuai
        dengan setiap. <br />
      </p>
      <h4 class="mt40">Pemberitahuan</h4>
      <p class="mb30 text-justify">
        Segala pemberitahuan kepada Anda dari Kami mengenai Layanan atau
        Ketentuan ini mungkin dibuat melalui email, pemberitahuan yang diposting
        pada Layanan, atau surat biasa, atas kebijakannya sendiri Kami. <br />
      </p>
      <h4 class="mt40">Komunikasi Elektronik</h4>
      <p class="mb30 text-justify">
        Ketika Anda mengakses atau menggunakan Layanan atau mengirim email
        kepada Kami. Anda menyetujui untuk menerima komunikasi dari Kami secara
        elektronik. Kami akan berkomunikasi dengan Anda melalui email atau
        melalui Layanan. Anda setuju itu semua ketentuan, pemberitahuan,
        pengungkapan, dan komunikasi lainnya yang Kami sediakan untuk Anda
        secara elektronik memenuhi persyaratan hukum apa pun bahwa komunikasi
        tersebut dibuat secara tertulis. Anda selanjutnya setuju bahwa
        pemberitahuan apa pun yang diberikan oleh Kami secara elektronik
        dianggap diberikan dan diterima pada tanggal Kami mengirimkan komunikasi
        elektronik seperti dijelaskan dalam Syarat dan Ketentuan ini. <br />
      </p>
      <h4 class="mt40">Hak Cipta</h4>
      <p class="mb30 text-justify">
        Merupakan kebijakan Kami untuk menghentikan hak keanggotaan dari setiap
        Pengguna yang berulang kali melanggar hak cipta setelah pemberitahuan
        segera ke Kami oleh pemilik hak cipta atau agen hukum pemilik hak cipta.
        Tanpa membatasi hal tersebut di atas, jika Anda percaya itu pekerjaan
        Anda telah disalin dan diposting di Layanan dengan cara yang merupakan
        pelanggaran hak cipta, berikan Agen Hak Cipta Kami hal-hal berikut
        informasi: (1) tanda tangan elektronik atau fisik dari orang yang
        berwenang untuk bertindak nama pemilik kepentingan hak cipta; (2)
        deskripsi karya berhak cipta bahwa Anda mengklaim telah dilanggar; (3)
        deskripsi lokasi pada Layanan materi yang Anda klaim melanggar; (4)
        alamat Anda, nomor telepon dan e- alamat email; (5) pernyataan tertulis
        oleh Anda bahwa Anda memiliki itikad baik bahwa penggunaan yang
        disengketakan tidak diizinkan oleh pemilik hak cipta, agennya atau
        hukum; dan (6) a pernyataan oleh Anda, dibuat di bawah sumpah palsu,
        bahwa informasi di atas di Anda pemberitahuan akurat dan bahwa Anda
        adalah pemilik hak cipta atau berwenang untuk bertindak atas nama
        pemilik hak cipta. Informasi kontak untuk Agen Hak Cipta Kami untuk
        pemberitahuan klaim pelanggaran hak cipta adalah sebagai berikut:
      </p>
      <h4 class="mt40">Seluruh ketentuan</h4>
      <p class="mb30 text-justify">
        Ketentuan ini dan ketentuan lainnya yang dapat diposkan Kami di Layanan
        atau bahwa Anda dan Kami dapat mengeksekusi dari waktu ke waktu
        merupakan seluruh ketentuan antara Kami dan Anda sehubungan dengan
        penggunaan Layanan oleh Anda dan menggantikan semua ketentuan sebelumnya
        antara Kami dan Anda tentang penggunaan Layanan, termasuk versi
        sebelumnya dari Ketentuan ini.
      </p>
      <h4 class="mt40">Peraturan pemerintah; Lokasi; Keterpisahan Ketentuan</h4>
      <p class="mb30 text-justify">
        Layanan ini dikendalikan dan dioperasikan oleh Kami dari kantor Kami di
        Jakarta. Mereka yang memilih untuk mengakses Layanan dari lokasi lain
        melakukannya sendiri inisiatif dan bertanggung jawab untuk kepatuhan
        terhadap hukum setempat, jika dan sejauh berlaku Akses ke Layanan dari
        yurisdiksi tempat konten Layanan Dilarang ilegal atau dihukum. <br />
        Validitas, interpretasi, konstruksi, dan kinerja Ketentuan ini akan
        menjadi diatur oleh Undang-Undang Negara Republik Indonesia, tanpa
        memperhatikan pertentangan ketentuan hukum.
      </p>
    </div>
  </section>
</template>

<script>
export default {
  name: "TermOfService",
  metaInfo: {
    title: "Term Of Service"
  }
};
</script>

<style scoped>
ol {
  list-style-type: lower-alpha;
}
ol > li {
  font-size: 14px;
}
</style>
